import Vue from 'vue';
import EStorage from '@beetronix/estorage';
import { customServices } from '@/configs/custom-pages.config';

Vue.use(EStorage);

const staticKeys = [
	{ key: 'orderHeaders', value: [] },
	{ key: 'productsHeaders', value: [] },
	{ key: 'newsHeaders', value: [] },
	{ key: 'servicesHeaders', value: [] },
	{ key: 'ordersHeaders', value: [] },
	{ key: 'plansHeaders', value: [] },
	{ key: 'couponsHeaders', value: [] },
	{ key: 'offersHeaders', value: [] },
	{ key: 'albumsHeaders', value: [] },
	{ key: 'videosHeaders', value: [] },
	{ key: 'slidersHeaders', value: [] },
	{ key: 'usersHeaders', value: [] },
	{ key: 'systemUsersHeaders', value: [] },
	{ key: 'branchesHeaders', value: [] },
];

customServices.forEach(customService => {
	staticKeys.push({ key: `customServiceHeaders${customService.type}`, value: [] })
});

const eStorage = new EStorage({
	useObjectKey: true,
	l: {
		staticKeys: staticKeys
	}
});

export { eStorage };