import wizardConfigs from './wizard-configs';
const { generalConfigs } = wizardConfigs;

export const useFileUpload = generalConfigs.useFileUpload;
export const useProductCode = generalConfigs.useProductCode;
export const useProductQuantitative = generalConfigs.useProductQuantitative;
export const useUpSellingForm = generalConfigs.useUpSellingForm;
export const useInHomePage = generalConfigs.useInHomePage;
export const useCustomizedRelated = generalConfigs.useCustomizedRelated;
export const useCategoryTree = generalConfigs.useCategoryTree;
export const useCategoryAttributes = generalConfigs.useCategoryAttributes;
export const useUploadCategoryImage = generalConfigs.useUploadCategoryImage;
export const useUploadFilterImage = generalConfigs.useUploadFilterImage;
export const useOrderingItems = generalConfigs.useOrderingItems;
export const useOneBranch = generalConfigs.useOneBranch;
export const useUploadBranchImage = generalConfigs.useUploadBranchImage;
export const useUploadCompanyValuesImage = generalConfigs.useUploadCompanyValuesImage;
export const useUploadCompanyHistoriesImage = generalConfigs.useUploadCompanyHistoriesImage;
export const useUploadMoreImages = {
	products: generalConfigs.useUploadMoreImages.products,
	news: generalConfigs.useUploadMoreImages.news,
	services: generalConfigs.useUploadMoreImages.services,
	customServices: generalConfigs.useUploadMoreImages.customServices,
	branches: generalConfigs.useUploadMoreImages.branches,
	companyValues: generalConfigs.useUploadMoreImages.companyValues,
	companyHistories: generalConfigs.useUploadMoreImages.companyHistories
};
export const useFeatured = {
	sets: generalConfigs.useFeatured.sets,
	categories: generalConfigs.useFeatured.categories
};
export const useRestore = {
	sets: generalConfigs.useRestore.sets,
	categories: generalConfigs.useRestore.categories,
	filters: generalConfigs.useRestore.filters,
	keywords: generalConfigs.useRestore.keywords,
	products: generalConfigs.useRestore.products,
	news: generalConfigs.useRestore.news,
	services: generalConfigs.useRestore.services,
	customServices: generalConfigs.useRestore.customServices,
	plans: generalConfigs.useRestore.plans,
	branches: generalConfigs.useRestore.branches
};
export const additionalUserInfo = {
	latestWebsiteVisit: generalConfigs.additionalUserInfo.latestWebsiteVisit
};
export const statistics = {
	conversionRate: generalConfigs.statistics.conversionRate,
	averageOrderValue: generalConfigs.statistics.averageOrderValue,
	cartAbandonedRate: generalConfigs.statistics.cartAbandonedRate,
	retentionRate: generalConfigs.statistics.retentionRate,
};
export const branchInfo = {
	countryCode: generalConfigs.branchInfo.countryCode,
	moneyCode: generalConfigs.branchInfo.moneyCode,
	address: generalConfigs.branchInfo.address,
	openingHours: generalConfigs.branchInfo.openingHours,
	location: generalConfigs.branchInfo.location
};
export const contacts = {
	email: generalConfigs.contacts.email,
	phone: generalConfigs.contacts.phone,
	mobile: generalConfigs.contacts.mobile,
	whatsapp: generalConfigs.contacts.whatsapp,
	telegram: generalConfigs.contacts.telegram,
	telegramChannel: generalConfigs.contacts.telegramChannel,
	facebook: generalConfigs.contacts.facebook,
	instagram: generalConfigs.contacts.instagram,
	twitter: generalConfigs.contacts.twitter,
	youtube: generalConfigs.contacts.youtube,
	linkedIn: generalConfigs.contacts.linkedIn,
	pinterest: generalConfigs.contacts.pinterest
};
export const aboutUs = {
	showAbout: generalConfigs.aboutUs.showAbout,
	showMission: generalConfigs.aboutUs.showMission,
	showVision: generalConfigs.aboutUs.showVision,
	showPolicy: generalConfigs.aboutUs.showPolicy,
	showQualityPolicy: generalConfigs.aboutUs.showQualityPolicy
};