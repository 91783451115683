import i18n from '@/i18n/i18n';

export const permissionTypes = [
	{ id: 1, name: i18n.t('permissionTypes.read') },
	{ id: 2, name: i18n.t('permissionTypes.write') },
	{ id: 3, name: i18n.t('permissionTypes.no-access') },
];

export const permissionType = {
	read: 1,
	write: 2,
	noAccess: 3
};

export const orderState = {
	approved: 0,
	inPreparation: 1,
	shipping: 2,
	delivered: 3,
	canceled: 4,
};

export const orderStates = [
	{ id: 0, name: i18n.t('orderStates.approved') },
	{ id: 1, name: i18n.t('orderStates.in-progress') },
	{ id: 2, name: i18n.t('orderStates.shipping') },
	{ id: 3, name: i18n.t('orderStates.delivered') },
	{ id: 4, name: i18n.t('orderStates.canceled') },
];

export const upSellingEntryType = {
	yesNo: 1,
	count: 2,
	multiChoice: 3,
	multiple: 4,
};

export const upSellingEntryTypes = [
	{ id: 1, name: i18n.t('upSellingEntryTypes.yes-no') },
	{ id: 2, name: i18n.t('upSellingEntryTypes.count') },
	{ id: 3, name: i18n.t('upSellingEntryTypes.multi-choice') },
	{ id: 4, name: i18n.t('upSellingEntryTypes.multiple') },
];

export const loyaltyPointState = {
	spent: 1,
	earned: 2,
}