<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="companyHistories"
			:ids="deleteItemId"
			@on-delete="fetchCompanyValues"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('CompanyHistories.the-company-history')}}
		</bee-delete-dialog>

		<!-- restore dialog -->
		<bee-restore-dialog
			v-model="restoreDialog"
			moduleType="companyHistories"
			:ids="restoreItemId"
			@on-restore="fetchCompanyValues"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase orange--text text-darken-1">{{$t('restore')}}</span> 
			{{$t('CompanyHistories.the-company-history')}}
		</bee-restore-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-2">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
							:to="{ name: 'company-history' }"
						>
							<v-icon>mdi-plus</v-icon>
                            {{$t('CompanyHistories.add-new-company-history')}}
						</v-btn>
					</v-col>
					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredCompanyValues"
			:loading="loading"
			dense
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- order -->
			<template v-slot:item.num="{ item }">
				{{item.num}}
			</template>
			
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.primaryImage.url"
					:height="45"
					:width="45"
					lazy-src="../../assets/placeholder-image.png"
					transition="scale-transition"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-center align-center">
					<template v-if="!item.deletedAt">
						<tooltip :text="$t('tooltip.edit')">
							<v-btn
								color="blue darken-4"
								dark
								small
								class="ms-1 px-1 flex-shrink-1"
								min-width="20px"
								:to="{ name: 'company-history', params: { slug: item.slug, id: item.id } }"
							><v-icon>mdi-pencil</v-icon></v-btn>
						</tooltip>
						<tooltip :text="$t('tooltip.delete')">
							<v-btn
								color="red darken-1"
								dark
								small
								class="ms-1 px-1"
								min-width="20px"
								@click="deleteItemId = item.id; deleteDialog = true"
							>
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</tooltip>
					</template>
					<tooltip v-else :text="$t('tooltip.restore')">
						<v-btn
							color="orange darken-1"
							dark
							small
							class="px-1"
							min-width="20px"
							@click="restoreItemId = item.id; restoreDialog = true"
						>
							<v-icon>mdi-restore</v-icon>
						</v-btn>
					</tooltip>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { useUploadCompanyHistoriesImage } from '@/configs/global.config';
import { mapState } from 'vuex';
export default {
	name: 'CompanyHistories',

	data: () => ({
		deleteDialog: false,
		deleteItemId: null,
		
		restoreDialog: false,
		restoreItemId: null,
		
		// filter
		search: null,
		isFiltered: false,

		// helpers
		loading: false,
	}),

	computed: {
		...mapState({
			companyHistories: state => state.companyHistories.companyHistories
		}),

		headers() {
			const headers = [
				{ text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },
				{ text: this.$t('headers.title'), value: 'title', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			];

			if (!useUploadCompanyHistoriesImage) {
				headers.splice(0, 1);
			}
			
			return headers;
		},
		
		filteredCompanyValues() {
			return this.filterByName(this.isFiltered, this.companyHistories, this.search, 'title');
		},
	},

	methods: {
		fetchCompanyValues() {
			this.loading = true;
			this.$store.dispatch('companyHistories/fetchAll').finally(() => {
				this.loading = false;
			})
		}
	},

	created() {
		this.fetchCompanyValues()
	}
}
</script>

<style>

</style>