import { render, staticRenderFns } from "./CompanyValue.vue?vue&type=template&id=e483cd86&"
import script from "./CompanyValue.vue?vue&type=script&lang=js&"
export * from "./CompanyValue.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports