<template>
	<div id="orders">
		<!-- settings dialog -->
		<v-dialog
			v-model="settingsDialog"
			max-width="350"
			content-class="rounded-medium"
		>
			<v-card>
				<v-card-title class="justify-center text-uppercase text-h6">
					{{$t('Orders.orders-settings')}}
				</v-card-title>
				<v-card-text class="pb-2">
					<v-row dense>
						<!-- max orders per day -->
						<v-col cols="12">
							<v-text-field
								v-model="maxOrdersPerDay"
								:label="$t('inputs.max-orders-per-day')"
								dense
								hide-details
								outlined
								class="rounded-medium"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="justify-end">
					<v-btn color="primary" class="rounded-medium">
						{{$t('save')}}
					</v-btn>
					<v-btn class="rounded-medium" @click="settingsDialog = false">
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
		<!-- confirm dialog -->
		<v-dialog
			v-model="confirmDialog"
			max-width="460"
			content-class="rounded-medium"
		>
			<v-card>
				<v-card-title class="justify-center text-uppercase">
					{{$t('Orders.change-order-state')}} 
				</v-card-title>
				<v-card-text class="pb-2 text-body-1">
					{{$t('are-you-sure-you-want-to')}} 
					<span v-html="$t('Orders.change-state-to-$', {
						state:
							`<span class='${getStatusColor(orderState, true)}'>
								${orderStates.find(c => c.id === orderState)?.name}
							</span>`
					})"></span>
				</v-card-text>
				<v-card-actions class="justify-end">
					<v-btn color="primary" class="rounded-medium">{{$t('confirm')}}</v-btn>
					<v-btn
						class="rounded-medium"
						@click="confirmDialog = false"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- calendar dialog -->
		<v-dialog
			v-model="calendarDialog"
			max-width="600"
			content-class="rounded-medium"
		>
			<v-card>
				<v-card-text class="py-4 px-2">
					<!-- calendar -->
					<div class="d-flex align-center mb-4">
						<!-- prev -->
						<v-btn icon class="me-1" @click="$refs.calendar.prev()">
							<v-icon>mdi-chevron-left</v-icon>
						</v-btn>

						<!-- calendar -->
						<v-calendar
							v-model="calendarDay"
							ref="calendar"
							:now="calendarDay"
							:events="ordersByDay"
							event-color="primary lighten-1 mt-1 mx-auto text-center rounded-small"
							color="primary"
							@click:date="date = calendarDay; calendarDialog = false"
						></v-calendar>

						<!-- next -->
						<v-btn icon class="ms-1" @click="$refs.calendar.next()">
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</div>

					<!-- buttons -->
					<div class="text-center">
						<v-btn
							width="42.5%"
							outlined
							color="primary"
							class="me-2 rounded-medium"
							@click="date = null; calendarDialog = false;"
						>{{$t('Orders.all')}}</v-btn>

						<v-btn
							width="42.5%"
							color="primary"
							class="rounded-medium"
							@click="date = moment().format('YYYY-MM-DD'); calendarDialog = false;"
						>{{$t('Orders.today')}}</v-btn>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-2">
				<v-row dense align="center">
					<!-- change settings -->
					<v-col md="auto" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="settingsDialog = true"
						>
							<v-icon class="me-1">mdi-file-cog</v-icon>
							{{$t('LoyaltyPoints.change-settings')}}
						</v-btn>
					</v-col>

					<v-spacer/>
					
					<!-- status filter -->
					<v-col lg="auto" md="8" cols="12" v-if="false">
						<v-chip-group
							v-model="orderStatusAction"
							mandatory
							show-arrows
							center-active
						>
							<v-chip
								v-for="(item, index) in orderStatusActions"
								:key="index"
								:value="item.value"
								:active-class="`${getStatusColor(item.value)} white--text`"
								class="rounded-medium"
							>
								{{item.title}}
							</v-chip>
						</v-chip-group>
					</v-col>

					<!-- status -->
					<v-col lg="2" md="3" sm="4" cols="6">
						<v-autocomplete
							v-model="status"
							:label="$t('Orders.order-status')"
							:items="paidStates"
							item-text="title"
							item-value="value"
							outlined
							dense
							hide-details
							clearable
							class="rounded-small"
						></v-autocomplete>
					</v-col>

					<!-- calendar -->
					<v-col lg="2" md="3" sm="4" cols="6">
						<v-text-field
							:label="$t('inputs.date')"
							dense
							hide-details
							outlined
							class="rounded-medium"
							readonly
							prepend-inner-icon="mdi-calendar-filter"
							:value="date"
							@click="calendarDialog = true"
						></v-text-field>
					</v-col>

					<!-- search -->
					<v-col lg="2" md="3" sm="4" cols="6">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium"
						></v-text-field>
					</v-col>
					
					<!-- search -->
					<v-col cols="auto">
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="customizedHeaders"
			:items="loading ? [] : filteredOrders"
			:loading="loading"
			:items-per-page="15"
			dense
			class="elevation-3 rounded-medium"
		>
			<!-- actions header -->
			<template v-slot:header.actions="{ header }">
				<div class="d-flex align-center" :class="`justify-${header.align}`">
					<span class="px-0" :class="header.class">{{header.text}}</span>
					<v-btn
						small
						icon
						@click="$eventBus.$emit(
							'headers-control',
							allHeaders.filter(c => !c.hide),
							customizedHeaders,
							(newHeaders) => {
								$eStorage.l.orderHeaders = newHeaders;
							}
						)"
					>
						<v-icon size="18">mdi-cog</v-icon>
					</v-btn>
				</div>
			</template>

			<!-- createdAt -->
			<template v-slot:item.createdAt="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">{{moment(item.createdAt).format('DD-MM-YYYY')}}</span>
					</template>
					{{moment(item.createdAt).format('dddd MMM YYYY, hh:mm a')}}
				</v-tooltip>
			</template>

			<!-- paid -->
			<template v-slot:item.paid="{ item }">
				<v-icon size="22" :color="item.paid ? 'success' : 'red lighten-1'">
					mdi-{{item.paid ? 'check' : 'close'}}-circle-outline
				</v-icon>
			</template>

			<!-- paymentType -->
			<template v-slot:item.paymentType="{ item }">
				{{item.paymentType || '-'}}
			</template>

			<!-- amount -->
			<template v-slot:item.amount="{ item }">
				{{item.amount || '-'}}
			</template>
			
			<!-- email -->
			<template v-slot:item.email="{ item }">
				{{item.email || '-'}}
			</template>
			
			<!-- phone -->
			<template v-slot:item.phone="{ item }">
				{{item.phone || '-'}}
			</template>
			
			<!-- transCode -->
			<template v-slot:item.transCode="{ item }">
				{{item.transCode || '-'}}
			</template>

			<!-- current status -->
			<template v-slot:item.currentStatus="{item}">
				<v-menu offset-y content-class="order-state-menu">
					<template v-slot:activator="{ on, attr }">
						<v-btn
							text
							v-on="on"
							v-bind="attr"
							class="text-capitalize rounded-small px-1"
							small
							max-width="154"
							min-width="0"
						>
							<v-icon size="30" :color="getStatusColor(item.status)">
								mdi-circle-medium
							</v-icon>
							<span class="ms-1 me-2" :class="getStatusColor(item.status, true)">
								{{orderStates.find(c => c.id === item.status)?.name}}
							</span>
							<v-icon color="grey darken-1">mdi-chevron-down</v-icon>
						</v-btn>
					</template>

					<v-list dense nav>
						<v-list-item
							v-for="state in orderStates"
							:key="state.id"
							dense
							:disabled="item.status === state.id"
							@click="orderState = state.id; itemId = item.id; confirmDialog = true;"
						>
							<v-list-item-title class="text-capitalize">
								<v-icon size="30" :color="getStatusColor(state.id)" class="me-1">
									mdi-circle-medium
								</v-icon>
								<span :class="getStatusColor(state.id, true)">{{state.name}}</span>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<tooltip :text="$t('tooltip.preview-order-details')">
					<div class="d-flex justify-center">
						<v-btn
							color="deep-purple darken-2"
							dark
							small
							class="me-1 px-1 flex-shrink-1"
							min-width="20px"
							:to="{ name: 'order', params: { id: item.id } }"
						><v-icon>mdi-eye</v-icon></v-btn>
					</div>
				</tooltip>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { orderState, orderStates } from '@/helpers/enums'

export default {
	name: 'Orders',
	data: () => ({
		orderStates,
		calendarDialog: false,
		calendarDay: null,

		// settings
		settingsDialog: false,
		maxOrdersPerDay: null,

		// filter
		orderStatusAction: null,
		status: null,
		search: null,
		date: null,
		isFiltered: false,

		// helpers
		itemId: null,
		orderState: null,
		confirmDialog: false,
		loading: false,
	}),

	computed: {
		...mapState({
			orders: state => state.orders.orders	
		}),

		orderStatusActions() {
			return [
				{ title: this.$t('Orders.all'), value: -1 },
				{ title: this.$t('orderStates.approved'), value: 0 },
				{ title: this.$t('orderStates.in-progress'), value: 1 },
				{ title: this.$t('orderStates.shipping'), value: 2 },
				{ title: this.$t('orderStates.delivered'), value: 3 },
				{ title: this.$t('orderStates.canceled'), value: 4 },
			]
		},
		paidStates() {
			return [
				{ title: this.$t('orderStates.paid'), value: 1 },
				{ title: this.$t('orderStates.unpaid'), value: 0 }
			]
		},

		allHeaders() {
			const headers = [
				{
					text: this.$t('headers.user'),
					value: 'fullName',
					align: 'center',
					class: 'primary--text',
					cellClass: 'primary--text',
					noRemove: true
				},
				{
					text: this.$t('headers.created-at'),
					value: 'createdAt',
					align: 'center'
				},
				{
					text: this.$t('headers.amount'),
					value: 'amount',
					align: 'center',
					notInDefaultView: true
				},
				{
					text: this.$t('headers.email'),
					value: 'email',
					align: 'center',
					notInDefaultView: true
				},
				{
					text: this.$t('headers.phone'),
					value: 'phone',
					align: 'center',
					notInDefaultView: true
				},
				{
					text: this.$t('headers.trans-code'),
					value: 'transCode',
					align: 'center',
					notInDefaultView: true
				},
				{
					text: this.$t('headers.total'),
					value: 'total',
					align: 'center'
				},
				{
					text: this.$t('headers.paid'),
					value: 'paid',
					align: 'center'
				},
				{
					text: this.$t('headers.payment-type'),
					value: 'paymentType',
					align: 'center'
				},
				{
					text: this.$t('headers.current-status'),
					value: 'currentStatus',
					align: 'center',
					width: 154,
					noRemove: true,
				},
				{
					text: this.$t('headers.actions'),
					value: 'actions',
					align: 'center',
					sortable: false,
					noRemove: true,
					fixed: true
				},
			];

			for (let i = 0; i < headers.length; i++) {
				headers[i].class = headers[i].class ? headers[i].class + ' px-2' : 'px-2'
				headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2'
			}

			return headers
		},

		customizedHeaders() {
			let headers = [];
			if (this.$eStorage.l.orderHeaders.length) {
				headers = this.$eStorage.l.orderHeaders;
			} else {
				headers = this.allHeaders.filter(c => !c.notInDefaultView);
			}
			return headers.filter(c => !c.hide);
		},

		filteredOrders() {
			return this.filterByName(this.isFiltered, this.orders, this.search || '', 'fullName')
				.filter(c => this.isFiltered && this.status !== null ? c.status === this.status : true)
		},

		ordersByDay() {
			const items = [];
			for (let i = 0; i < 5; i++) {
				items.push({
					name: Math.round(Math.random() * 10) + 1 + ' orders',
					start: moment().add(Math.round(Math.random() * 30) + 1, 'days').format('YYYY-MM-DD')
				})
			}
			return items
		}
	},

	methods: {
		getStatusColor(state, isText = false) {
			switch (state) {
			case orderState.approved : return `light-blue${isText ? '--text text--' : ' '}darken-1`;
			case orderState.inPreparation : return `deep-orange${isText ? '--text' : ''}`;
			case orderState.shipping : return `indigo${isText ? '--text text--' : ' '}darken-4`;
			case orderState.delivered : return `success${isText ? '--text' : ''}`;
			case orderState.canceled : return `red${isText ? '--text text--' : ' '}lighten-1`;
			default : return `primary${isText ? '--text' : ''}`
			}
		},

		// helpers
		moment
	},

	created() {
		this.loading = true;
		this.$store.dispatch('orders/fetchAll').finally(() => {
			this.loading = false;
		})
	}
}
</script>

<style lang="scss" scoped>
.order-state-menu .v-list-item {
	min-height: 30px;
}
</style>