import { products } from '@/configs/translates.config';

export default {
	'add-new-product': `add new ${products.en.singular}`,
	'the-product': `the ${products.en.singular}?`,
	product: products.en.singular,
	confirm: 'confirm',
	'are-you-sure-you-want-to-$-this-user': `Are you sure you want to {state} this ${products.en.singular}?`,
	activate: 'activate',
	deactivate: 'deactivate',
	'up-selling-form': 'up selling form',
	entry: 'entry',
	choices: 'choices',
	'offer-data': 'offer data',
}