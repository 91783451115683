<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="services"
			:ids="deleteItemId"
			@on-delete="fetchServices"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Services.the-service')}}
		</bee-delete-dialog>

		<!-- restore dialog -->
		<bee-restore-dialog
			v-model="restoreDialog"
			moduleType="services"
			:ids="restoreItemId"
			@on-restore="fetchServices"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase orange--text text-darken-1">{{$t('restore')}}</span> 
			{{$t('Services.the-service')}}
		</bee-restore-dialog>

		<!-- order dialog -->
		<v-dialog
			v-model="orderDialog"
			width="300"
			:persistent="changeOrderLoading"
		>
			<v-form ref="orderFrom" @submit.prevent="changeOrder(false, orderItem)">
				<v-card class="rounded-medium">
					<v-card-title class="justify-center text-uppercase text-h6">
						{{ $t('changing-order') }}
					</v-card-title>
					<v-card-text class="text-body-1 pb-2">
						<v-text-field
							v-model.number="orderItem"
							:label="$t('inputs.order')"
							dense
							outlined
							hide-details
							class="rounded-medium"
							:rules="rules.requiredAsNumber"
						></v-text-field>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							color="primary"
							class="rounded-small"
							:loading="changeOrderLoading"
							:disabled="changeOrderLoading"
							type="submit"
						>
							{{$t('change')}}
						</v-btn>
						<v-btn
							class="rounded-small"
							:disabled="changeOrderLoading"
							@click="orderDialog = false;"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-2">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
							:to="{ name: 'service' }"
						>
							<v-icon>mdi-plus</v-icon>
                            {{$t('Services.add-new-service')}}
						</v-btn>
					</v-col>

					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="customizedHeaders"
			:items="loading ? [] : filteredServices"
			:loading="loading || orderLoading"
			dense
			:items-per-page="15"
			:item-class="item => dragIndex === services.indexOf(item) ? 'drag-item' : null"
			class="elevation-3 rounded-medium"
		>
			<!-- actions header -->
			<template v-slot:header.actions="{ header }">
				<div class="d-flex align-center" :class="`justify-${header.align}`">
					<span class="px-0" :class="header.class">{{header.text}}</span>
					<v-btn
						small
						icon
						@click="$eventBus.$emit(
							'headers-control',
							allHeaders.filter(c => !c.hide),
							customizedHeaders,
							(newHeaders) => {
								$eStorage.l.servicesHeaders = newHeaders;
							}
						)"
					>
						<v-icon size="18">mdi-cog</v-icon>
					</v-btn>
				</div>
			</template>

			<!-- order -->
			<template v-slot:item.num="{ item }">
				{{item.num}}
			</template>
			
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.primaryImage.url"
					:height="45"
					:width="45"
					lazy-src="../../assets/placeholder-image.png"
					transition="scale-transition"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- summary -->
			<template v-slot:item.summary="{ item }">
				{{item.summary | isAvailable | ellipsis}}
			</template>

			<!-- date -->
			<template v-slot:item.date="{ item }">
				{{item.date ? moment(item.date).format('DD-MM-YYYY') : '-'}}
			</template>

			<!-- is featured -->
			<template v-slot:item.isFeatured="{ item }">
				<v-icon v-if="item.isFeatured">mdi-check-circle-outline</v-icon>
				<span v-else>-</span>
			</template>

			<!-- is home page -->
			<template v-slot:item.inHomePage="{ item }">
				<v-icon v-if="item.inHomePage">mdi-check-circle-outline</v-icon>
				<span v-else>-</span>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item, index }">
				<div
					class="d-flex justify-center align-center"
					@dragover.prevent
					@dragend="dragIndex = null;"
					@dragenter="dragIndex = index"
					@drop="
						orderItemId !== item.id
						? (() => {
							getDataOnDrop($event, index, services);
							changeOrder(true, index + 1);
						})()
						: null;
					"
				>
					<template v-if="!item.deletedAt">
						<template v-if="showPreview && previewUrl">
							<tooltip :text="$t('tooltip.preview-on-website')">
								<v-btn
									color="deep-purple darken-2 white--text"
									small
									class="px-1 flex-shrink-1"
									min-width="20px"
									:to="{ name: 'preview', query: { url: `${previewUrl}/${item.slug}` } }"
								><v-icon>mdi-eye</v-icon></v-btn>
							</tooltip>
							
							<tooltip :text="$t('tooltip.copy-url')">
								<v-btn
									color="blue darken-2 white--text"
									small
									class="px-1 flex-shrink-1 ms-1"
									min-width="20px"
									@click="copyToClipboard(`${previewUrl}/${item.slug}`)"
								><v-icon>mdi-link-variant</v-icon></v-btn>
							</tooltip>
							
							<v-divider vertical class="ms-1 grey darken-1"/>
						</template>
						
						<tooltip :text="$t('tooltip.edit')">
							<v-btn
								color="blue darken-4"
								dark
								small
								class="ms-1 px-1 flex-shrink-1"
								min-width="20px"
								:to="{ name: 'service', params: { slug: item.slug, id: item.id } }"
							><v-icon>mdi-pencil</v-icon></v-btn>
						</tooltip>
						
						<tooltip v-if="useOrderingItems" :text="$t('tooltip.change-order')">
							<v-btn
								color="teal darken-1"
								dark
								small
								class="ms-1 px-1"
								min-width="20px"
								@click="orderItemId = item.id; orderItem = item.num; orderDialog = true;"
							>
								<v-icon>mdi-swap-vertical</v-icon>
							</v-btn>
						</tooltip>
						
						<tooltip :text="$t('tooltip.delete')">
							<v-btn
								color="red darken-1"
								dark
								small
								class="ms-1 px-1"
								min-width="20px"
								@click="deleteItemId = item.id; deleteDialog = true"
							>
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</tooltip>
						
						<!-- drag Icon -->
						<tooltip v-if="useOrderingItems" :text="$t('tooltip.drag-to-change-order')">
							<div
								class="ms-2"
								:draggable="!orderLoading"
								@dragstart="
									orderItemId = item.id;
									setDataOnDrag($event, index);
								"
							>
								<v-icon>mdi-drag-vertical</v-icon>
							</div>
						</tooltip>
					</template>

					<tooltip v-else :text="$t('tooltip.restore')">
						<v-btn
							color="orange darken-1"
							dark
							small
							class="px-1"
							min-width="20px"
							@click="restoreItemId = item.id; restoreDialog = true"
						>
							<v-icon>mdi-restore</v-icon>
						</v-btn>
					</tooltip>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment'
import { service as servicePreview } from '@/configs/preview.config'
import { useInHomePage, useOrderingItems } from '@/configs/global.config';
import { copyToClipboard, getDataOnDrop, setDataOnDrag } from '@/helpers/functions';
import rules from '@/helpers/validation rules';
import { preview } from '@/configs/routes.config';

export default {
	name: 'Services',

	data: () => ({
		deleteDialog: false,
		deleteItemId: null,
		
		restoreDialog: false,
		restoreItemId: null,

		orderDialog: false,
		changeOrderLoading: false,
		orderLoading: false,
		orderItemId: null,
		orderItem: null,

		// configs
		showPreview: preview.show,
		useOrderingItems,

		// filter
		search: null,
		isFiltered: false,

		// helpers
		loading: false,
		previewUrl: servicePreview,
		dragIndex: null,

		rules,
	}),

	watch: {
		orderDialog(val) {
			if (!val) {
				this.$refs.orderFrom.reset();
				this.orderItemId = null;
			}
		}
	},

	computed: {
		...mapState({
			services: state => state.services.services
		}),

		allHeaders() {
			const headers = [
				{
					text: this.$t('headers.order'),
					value: 'num',
					align: 'center',
					width: 80,
					class: 'ps-3 px-1',
					cellClass: 'px-2',
					hide: !useOrderingItems,
					noRemove: true
				},
				{
					text: this.$t('headers.image'),
					value: 'image',
					align: 'center',
					sortable: false
				},
				{
					text: this.$t('headers.title'),
					value: 'title',
					align: 'center',
					class: 'primary--text',
					cellClass: 'primary--text',
					noRemove: true
				},
				{
					text: this.$t('headers.summary'),
					value: 'summary',
					align: 'center'
				},
				{
					text: this.$t('headers.code'),
					value: 'code',
					align: 'center',
					notInDefaultView: true,
				},
				{
					text: this.$t('headers.date'),
					value: 'date',
					align: 'center',
					width: 120
				},
				{
					text: this.$t('headers.featured'),
					value: 'isFeatured',
					align: 'center'
				},
				{
					text: this.$t('headers.in-home'),
					value: 'inHomePage',
					align: 'center',
					hide: !useInHomePage
				},
				{
					text: this.$t('headers.actions'),
					value: 'actions',
					align: 'center',
					width: 200,
					sortable: false,
					noRemove: true,
					fixed: true
				},
			];
			
			for (let i = 0; i < headers.length; i++) {
				headers[i].class = headers[i].class ? headers[i].class + ' px-2' : 'px-2'
				headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2'
			}

			return headers;
		},

		customizedHeaders() {
			let headers = [];
			if (this.$eStorage.l.servicesHeaders.length) {
				headers = this.$eStorage.l.servicesHeaders;
			} else {
				headers = this.allHeaders.filter(c => !c.notInDefaultView);
			}
			return headers.filter(c => !c.hide);
		},

		filteredServices() {
			return this.filterByName(this.isFiltered, this.services, this.search, 'title');
		}
	},

	methods: {
		fetchServices() {
			this.loading = true;
			this.$store.dispatch('services/fetchAll', { useOrder: useOrderingItems })
				.finally(() => {
					this.loading = false;
				})
		},

		changeOrder(useOrderLoading, order) {
			if (useOrderLoading) {
				this.orderLoading = true;
			} else {
				this.changeOrderLoading = true;
			}
			this.$store.dispatch('services/changeOrder', {
				id: this.orderItemId,
				order,
			}).then(() => {
				this.orderDialog = false;
				this.$eventBus.$emit('show-snackbar', false, this.$t('messages.item-order-is-updated'));
				if (useOrderLoading) {
					this.$store.dispatch('services/fetchAll', { useOrder: useOrderingItems });
				} else {
					this.fetchServices();
				}
			}).finally(() => {
				this.orderLoading = false;
				this.changeOrderLoading = false;
			})
		},

		// helpers
		moment,
		copyToClipboard,
		setDataOnDrag,
		getDataOnDrop,
	},

	created() {
		this.fetchServices();
	}
};
</script>

<style>
</style>