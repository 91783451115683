<template>
	<div>
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="sliders"
			:ids="deleteItemId"
			@on-delete="fetchSliders"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Sliders.the-slider')}}
		</bee-delete-dialog>

		<!-- add/edit dialog -->
		<v-dialog
			v-model="dialog"
			width="400"
			:persistent="dialogLoading"
		>
			<v-form ref="form" @submit.prevent="submit" :disabled="dialogLoading">
				<v-card class="rounded-medium">
					<v-card-title
						class="justify-center text-uppercase text-h6"
						:class="{ 'mb-3': !multiLangInputs, 'pb-2': multiLangInputs }"
					>
						{{ editItemId ? $t('edit') : $t('add')}} {{$t('Sliders.slider')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<!-- lang tabs -->
						<v-lang-tabs
							v-if="multiLangInputs"
							v-model="lang"
							:disable-non-selected="dialogLoading"
							background-color="bgColor"
							class="mb-4"
							@change="updateFieldsFromDraft"
						/>

						<!-- inputs -->
						<v-row class="flex-column mt-0">
							<v-col class="pt-0 pb-6">
								<bee-drop-img
									v-model="image"
									dashed
									border-width="2"
									:border-color="isSubmitted & !image ? '#f00' : '#ccc'"
									radius="11"
									height="180px"
									blurable
									clearable
									:loading="dialogLoading"
									:disabled="dialogLoading"
									disable-file-picker
									@remove-image="fileManagerFiles.mainImage = {}"
									@click="$eventBus.$emit('file-manager', { images: true }, (url, file) => {
										image = url;
										fileManagerFiles.mainImage = file;
									})"
								>
									<template v-slot:drag-text>
										<span class="text-body-1 font-weight-medium">
											{{$t('drag-text')}}
										</span>
										<span class="text-caption mb-0">
											{{$t('supported-text')}}
										</span>
									</template>
								</bee-drop-img>
							</v-col>
							<v-col class="py-0">
								<v-text-field
									v-model="description"
									:label="$t('inputs.description')"
									outlined
									dense
									class="rounded-small"
									:rules="rules.required"
								></v-text-field>
							</v-col>
							<v-col class="py-0">
								<v-text-field
									v-model="link"
									:label="$t('inputs.link')"
									outlined
									dense
									class="rounded-small"
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							class="rounded-small"
							:loading="dialogLoading"
							:disabled="dialogLoading"
							@click="isSubmitted = true"
						>
							{{$t('save')}}
						</v-btn>
						<v-btn
							@click="dialog = false; editItemId = null"
							class="rounded-small"
							:disabled="dialogLoading"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-2">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
                            {{$t('Sliders.add-new-slider')}}
						</v-btn>
					</v-col>
					
					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredSliders"
			:loading="loading"
			dense
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.image"
					lazy-src="../assets/placeholder-image.png"
					height="200"
					min-width="200"
					max-width="600"
					transition="scale-transition"
					class="rounded-medium my-2 mx-auto"
				/>
			</template>

			<!-- Link -->
			<template v-slot:item.url="{ item }">
				<a v-if="item.url" target="_blank" :href="item.url"> {{item.url}} </a>
				<span v-else>-</span>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-center">
					<tooltip :text="$t('tooltip.edit')">
						<v-btn
							color="blue darken-4"
							dark
							small
							class="me-1 px-1 flex-shrink-1"
							min-width="20px"
							@click="editItemId = item.id; dialog = true;"
						><v-icon>mdi-pencil</v-icon></v-btn>
					</tooltip>
					<tooltip :text="$t('tooltip.delete')">
						<v-btn
							color="red darken-1"
							dark
							small
							class="px-1"
							min-width="20px"
							@click="deleteItemId = item.id; deleteDialog = true"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</tooltip>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex'
import { defaultLang, langs, multiLangInputs } from '@/configs/translates.config'
import langsDraft from '@/mixins/langs-draft'

export default {
	name: 'Products',
	mixins: [langsDraft],

	data: () => ({
		deleteDialog: false,
		deleteItemId: null,
		editItemId: null,
		dialog: false,

		// configs
		multiLangInputs,

		// file manager
		fileManagerFiles: {
			mainImage: {},
		},

		// filter
		search: null,
		isFiltered: null,

		// helpers
		loading: false,
		dialogLoading: false,
		isSubmitted: false,
		lang: defaultLang,
		rules,

		// draft for languages 
		draft: {},
		draftNames: ['description'],

		// data
		image: null, 
		description: null,
		link: null,
	}),

	watch: {
		dialog(val) {
			if (val) {
				if (this.editItemId) {
					if (multiLangInputs) {
						this.fetchSlider();
					} else {
						const item = this.getSlideById(this.editItemId);
						this.image = item.image;
						this.description = item.description;
						this.link = item.url;
					}
				}
			} else {
				this.$refs.form.reset();
				this.image = null;
				this.editItemId = null;
				this.lang = defaultLang;
				this.isSubmitted = false;
			}
		}
	},

	computed: {
		...mapState({
			sliders: state => state.sliders.sliders,
		}),
		...mapGetters({
			getSlideById: 'sliders/getSlideById'
		}),

		headers() {
			return [
				{ text: this.$t('headers.image'), value: 'image', align: 'center' },
				{ text: this.$t('headers.description'), value: 'description', align: 'center', sortable: false },
				{ text: this.$t('headers.link'), value: 'url', align: 'center' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center' },
			]
		},

		filteredSliders() {
			return this.filterByName(this.isFiltered, this.sliders, this.search, 'title')
		},

		items() {
			const items = [];
			for (let i = 0; i < 15; i++) {
				items.push({
					id: i, 
					image: 'https://www.metoffice.gov.uk/binaries/content/gallery/metofficegovuk/hero-images/advice/maps-satellite-images/satellite-image-of-globe.jpg',
					description: 'description ' + i,
					url: 'http://www.facebook.com',
				})
			}
			return items;
		}
	},
	methods: {
		async submit() {
			if (this.$refs.form.validate() && this.image) {
				this.dialogLoading = true;

				const promises = [];
				let itemData = null;

				const submitRequest = (
					isDefaultOne,
					{ description, lang },
					id = this.editItemId
				) => {
					const actionName = id ? 'update' : 'create';

					return this.$store.dispatch(`sliders/${actionName}`, {
						id: id,
						image:
							isDefaultOne && typeof this.image !== 'string'
								? this.image
								: null,
						imagePath:
							isDefaultOne && typeof this.image === 'string'
								? this.fileManagerFiles.mainImage.publicPath
								: null,
						imageName:
							isDefaultOne && typeof this.image === 'string'
								? this.fileManagerFiles.mainImage.fileName
								: null,
						description: description,
						url: this.link,
						acceptLanguage: lang
					})
				};

				const filteredLangs = langs.filter(c => multiLangInputs || c.key === defaultLang);
				for (let index = 0; index < filteredLangs.length; index++) {
					const lang = filteredLangs[index].key;

					const description = this.draft.description[lang];

					const dataDueLang = { description, lang };

					if (description) {
						if (index === 0) {
							await submitRequest(index === 0, dataDueLang).then(data => { itemData = data });
						} else {
							promises.push(submitRequest(false, dataDueLang, itemData.id));
						}
					}
				}

				Promise.all(promises).then((data) => {
					this.isSubmitted = false;

					const message =
						this.editItemId
							? this.$t('$-is-updated-successfully', { name: this.$t('Sliders.slider') })
							: this.$t('$-is-created-successfully', { name: this.$t('Sliders.slider') });
					this.$eventBus.$emit('show-snackbar', false, message);
					this.fetchSliders();
					this.dialog = false;
				}).catch(() => {
					this.editItemId = itemData.id;
				}).finally(() => {
					this.dialogLoading = false;
				})
			}
		},
		
		fetchSlider() {
			this.dialogLoading = true;

			const promises = [];
			langs.filter(c => multiLangInputs || c.key === defaultLang).forEach((c, index) => {
				promises.push(
					this.$store.dispatch('sliders/fetchById', { id: this.editItemId, acceptLanguage: c.key })
						.then((data) => {
							if (index === 0) {
								this.image = data.image;
								this.link = data.url;
							}
							this.draft.description[c.key] = data.description; 
						})
				)
			});
			Promise.all(promises).then((data) => {
				this.description = this.draft.description[this.lang];
			}).finally(() => {
				this.dialogLoading = false;
			})
		},
		
		fetchSliders() {
			this.loading = true;
			this.$store.dispatch('sliders/fetchAll')
				.finally(() => {
					this.loading = false;
				})
		}
	},

	created() {
		this.fetchSliders();
	}	
}
</script>

<style>
</style>